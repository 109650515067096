import * as yup from "yup";
const validationsForm =  yup.object().shape({
  new_name: yup.string().required('Training Program / Course Name is required'),
  new_centerinstitutecompany: yup.string().required('Center/ Institute / Company is required'),
  new_periodhours: yup.string().required('Period (Hours) is required'),
  new_fromdate: yup.string().required('From is required'),
  new_todate: yup.string().required('To is required'),
  new_descriptionobjectivecontentacquiredskills: yup.string().required('Description is required'),

});

export default validationsForm;
