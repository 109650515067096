import * as yup from "yup";
const validationsForm =  yup.object().shape({
  new_languaget: yup.object().shape({
    value: yup.string().required("Required"),
    name: yup.string().required(),
  }),
  new_understandt: yup.object().shape({
    value: yup.string().required("Required"),
    name: yup.string().required(),
  }),
  new_speakt: yup.object().shape({
    value: yup.string().required("Required"),
    name: yup.string().required(),
  }),
  new_readwritet: yup.object().shape({
    value: yup.string().required("Required"),
    name: yup.string().required(),
  }),
 
});

export default validationsForm;
