import React from 'react'
import { Typography, Grid  } from '@mui/material';
function Header() {
  return (
    <Grid container spacing={2}>
        <Grid item xs={11} sm={4}>
        <img src='https://rowad-rme.com/wp-content/uploads/Rowad-Logo.png' height={70}/>
        </Grid>
        <Grid item xs={11} sm={8}>
        <div className="wrapper">
        <Typography variant="h6" gutterBottom fontWeight="bold">
            Career
        </Typography>
        </div>
        </Grid>
    </Grid>
  )
}

export default Header