import React from 'react';
import Swal from 'sweetalert2'
import Header from '../Element/Header';
import withReactContent from 'sweetalert2-react-content'
import { Container, Box, Grid, Alert,
  Paper, Button, TextField, InputAdornment, IconButton,CircularProgress } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import SkeletonTypography from '../Element/Skeleton';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function JobNames_HRequest({tag}) {
  const [JobNames, setJobNames] = React.useState([]);
  const [searchInput, setSearchInput] = React.useState('');
  const [filteredJobNames, setFilteredJobNames] = React.useState([]);
  const [loading,setLoading]=React.useState(true);
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  React.useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_Back_URL}/careers/hrequests${tag}`,
    ];

    Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
      .then((data) => {
        setLoading(false)
        data.forEach((resp, index) => {
          if (index === 0) {
            let arr = resp.data.sort((a, b) => (a.new_jobnamestring.toUpperCase() > b.new_jobnamestring.toUpperCase()) ? 1 : ((b.new_jobnamestring.toUpperCase() > a.new_jobnamestring.toUpperCase()) ? -1 : 0))
            setJobNames(arr.map((row) => {
              return {
                id: row.new_hrequestid,
                name: row.new_jobnamestring,
                code: row.new_name,
                sitelocation: row.new_new_sitelocation,
                minimumexperience: row.new_minimumexperience,
                site: row.new_sitestring,
                specificrequirements: row.new_specificrequirements,
              }
            }))
          }
        })
      })
      .catch(function (error) {
        if(window.location.href.includes("rowad-app")){
          MySwal.fire({
            icon: "error",
            title: "Error",
            showConfirmButton: false,
            html: `
            <span style="text-align:left; font-size:20px;">
            If theres you're unable to connect server,
            <br/> <br/>
            <strong>Try this alternative link</strong>
            <br/><br/>
            
            <strong style="font-size:30px"><a href="http://rowad-app.com/mega-project">Click here</a> </strong>
            </span>
            `
            })
        } else{
          const currentUrl = window.location.href;
          const currentPath = new URL(currentUrl).pathname;
          const redirectTo = `http://rowad-app.com${currentPath}`;
      
          window.location.href = redirectTo;
        }
      })
      ;
  }, []);

  React.useEffect(() => {
    const filteredNames = JobNames.filter(
      (job) =>
        job.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        job.code.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredJobNames(filteredNames);
  }, [JobNames, searchInput]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleApplyClick = (id) => {
    if(window.location.href.includes("mega-project")){
    navigate('/mega-project/jobcode/' + id);
    }else{
      navigate('/jobcode/' + id);
    }

  };

  return (
    <Container>
      <Box sx={{ bgcolor: 'rgb(255 255 255)', textAlign: 'left', padding: '30px' }}>
        <Header />
        <Grid container spacing={2}>
          <Grid item xs={13} sm={6}>
          {tag==="General"?(<h1>Available Vacancies</h1>):(<h1>Dameitta Port Project</h1>)}
          </Grid>
          <Grid item xs={13} sm={6}><h1>
          <Alert severity="info" color="warning">If you couldn't find a suitable vacancy listed below, please  <a href="/applynow">apply here</a></Alert>
  </h1>
          </Grid>
    
      </Grid>
       
        <br />
       { loading ? 
       <SkeletonTypography/>:
        <>
          <TextField
            type="text"
            value={searchInput}
            onChange={handleSearchInputChange}
            placeholder="Search by job name or code"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" disabled>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {filteredJobNames.map((job, index) => (
              <Grid item  xs={11} sm={4} key={index}>
                <h4> {job.name}  | {job.code}</h4>
                <p><strong>Experience: </strong>{job.minimumexperience}</p>
                <p><strong>Location: </strong>{job.site}</p>
                <p><strong>Requirements: </strong> {job.specificrequirements}</p>
                <Button variant="contained" color="primary" onClick={() => handleApplyClick(job.code)}>
                  Apply Now
                </Button>
              </Grid>
            ))}
          </Grid>
          <br/>

        </>
      }
      </Box>
    </Container>
  );
}

export default JobNames_HRequest;