import * as yup from "yup";

const validationsForm =  yup.object().shape({
new_jobnameid: yup.object().shape({
    id: yup.string().required("Required"),
    name: yup.string().required(),
    jobnameid: yup.string().required(),
  }),
  new_dateavailabletxt: yup.string().required("Required"),
new_expectedsalaryt: yup.number().moreThan(0, 'Should not be zero or less than zero').required("Required"),
new_availabletotravel: yup.boolean().required("Required"),
new_ifyouwillingtotravel: yup

.string()
.when("new_availabletotravel", {
  is: (value) => value && value === true,
  then: (schema) => schema.required('Required'),
  otherwise: (schema) => schema.optional(),
}),
new_howdidyouhearaboutrowad: yup.string().required("Required"),
new_doyoupossessavaliddrivinglicense: yup.boolean().required("Required"),
new_reason: yup.string().required("Required"),

//   email: yup
//     .string()
//     .email("Enter a valid email")
//     .required("Email is required"),
//   course: yup.string().required("Select your course category"),
//   password: yup
//     .string()
//     .min(8, "Password must contain at least 8 characters")
//     .required("Enter your password"),
//   confirmPassword: yup
//     .string()
//     .oneOf([yup.ref("password")], "Password does not match")
//     .required("Confirm your password"),
//   website: yup
//     .string()
//     .url()
//     .required("Website is required")
});

export default validationsForm;
