import React from 'react';
import validationsForm from "./ValidationSchema";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {TableHead ,TableRow ,TableCell ,TableContainer,Table , TableBody,Box, Modal, Fade,Backdrop,
  Typography, TextField, Button, MenuItem, Grid,IconButton   } from '@mui/material';
import { Edit, Delete } from '@material-ui/icons';
function WorkExpTable({ data, handleEdit, handleDelete }) {
    const [open, setOpen] = React.useState(false);
    const formik= useFormik({
      initialValues:{
        id: 0,
        new_name: '',                          // Company
        new_field: '',                         // Field
        new_positiontitle: '',                 // Job Title
        new_projecttype: '',                   // Project Type
        new_salary: '',                        // Salary
        new_employedfrom: '',                  // From
        new_employedtotxt: '',                 // To
        new_responsibilitiesachievements: '',  // Resposibility & Acheivement
        new_reasonforleaving:'',               // Reason for Leaving
      },
      validationSchema: validationsForm,
      
      onSubmit: values => { 
        const{new_employedfrom,new_employedtotxt,...rest}=values
        handleEdit({
          "new_employedfrom": new_employedfrom.$y+'-'+Number(new_employedfrom.$M+1)+'-'+new_employedfrom.$D,
          "new_employedtotxt":  new_employedtotxt.$y+'-'+Number(new_employedtotxt.$M+1)+'-'+new_employedtotxt.$D,
          ...rest
        },data,"Work Experience");
        handleClose();
     },
    });

 
    const handleOpen = (rowData) => {
      setOpen(true);
      const {new_employedfrom,new_employedtotxt,...rest}=rowData
      formik.setValues({"new_employedfrom":dayjs(new_employedfrom),"new_employedtotxt":dayjs(new_employedtotxt),...rest});
    };
  
    const handleClose = () => {
      setOpen(false);
      formik.setTouched({});
    };
 
  
    return (
      <TableContainer>
        <Table>
          {data.length>0?( //Appear Table Header if theres at 1 data
          <TableHead>
            <TableRow>
              <TableCell>Company</TableCell>
              <TableCell>Field</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Project Type</TableCell>
              <TableCell>Salary</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Resposibility & Acheivement</TableCell>
              <TableCell>Reason for Leaving</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          ):<></>}
          <TableBody>
            
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.new_name}</TableCell>
                <TableCell>{row.new_field}</TableCell>
                <TableCell>{row.new_positiontitle}</TableCell>
                <TableCell>{row.new_projecttype}</TableCell>
                <TableCell>{row.new_salary}</TableCell>
                <TableCell>{row.new_employedfrom}</TableCell>
                <TableCell>{row.new_employedtotxt}</TableCell>
                <TableCell>{row.new_responsibilitiesachievements}</TableCell>
                <TableCell>{row.new_reasonforleaving}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpen(row)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.id,"Work Experience")}>
                  <Delete />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
        <Fade in={open}>
          <Box className='popupaddedit'>
          <Typography id="transition-modal-title" variant="h4" component="h2">
            Edit Work Experience 
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_name"
                                label="Company"
                                value={formik.values.new_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.new_name && formik.errors.new_name}
                                error={ formik.touched.new_name  && Boolean(formik.errors.new_name)}
                                placeholder="WorkExp"
                                inputProps={{
                                  maxlength: 100
                                }}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_field"
                                label="Field"
                                value={formik.values.new_field}
                                onChange={formik.handleChange}
                                inputProps={{
                                  maxlength: 100
                                }}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.new_field && formik.errors.new_field}
                                error={ formik.touched.new_field  && Boolean(formik.errors.new_field)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_positiontitle"
                                label="Job Title"
                                inputProps={{
                                  maxlength: 100
                                }}
                                value={formik.values.new_positiontitle}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.new_positiontitle && formik.errors.new_positiontitle}
                                error={ formik.touched.new_positiontitle  && Boolean(formik.errors.new_positiontitle)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_projecttype"
                                label="Project Type"
                                value={formik.values.new_projecttype}
                                inputProps={{
                                  maxlength: 100
                                }}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.new_projecttype && formik.errors.new_projecttype}
                                error={ formik.touched.new_projecttype  && Boolean(formik.errors.new_projecttype)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_salary"
                                label="Salary"
                                value={formik.values.new_salary}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.new_salary && formik.errors.new_salary}
                                error={ formik.touched.new_salary  && Boolean(formik.errors.new_salary)}
                                margin="normal"
                                inputProps={{
                                  maxlength: 100
                                }}
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="From"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                value={ formik.values.new_employedfrom || null}
                                onChange={(date) => formik.setFieldValue('new_employedfrom',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formik.touched.new_employedfrom  && Boolean(formik.errors.new_employedfrom),
                                    helperText:formik.touched.new_employedfrom && formik.errors.new_employedfrom
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="To"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                // format='YYYY-MM-DD'
                                value={ formik.values.new_employedtotxt || null}
                                onChange={(date) => formik.setFieldValue('new_employedtotxt',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formik.touched.new_employedtotxt  && Boolean(formik.errors.new_employedtotxt),
                                    helperText:formik.touched.new_employedtotxt && formik.errors.new_employedtotxt
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
 
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_responsibilitiesachievements"
                                label="Resposibility & Acheivement"
                                value={formik.values.new_responsibilitiesachievements}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.new_responsibilitiesachievements && formik.errors.new_responsibilitiesachievements}
                                error={ formik.touched.new_responsibilitiesachievements  && Boolean(formik.errors.new_responsibilitiesachievements)}
                                margin="normal"
                                inputProps={{
                                  maxlength: 2000
                                }}
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_reasonforleaving"
                                label="Reason for Leaving"
                                inputProps={{
                                  maxlength: 200
                                }}
                                value={formik.values.new_reasonforleaving}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.new_reasonforleaving && formik.errors.new_reasonforleaving}
                                error={ formik.touched.new_reasonforleaving  && Boolean(formik.errors.new_reasonforleaving)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <Button variant="contained"  color="success" type="button" onClick={formik.handleSubmit}>
                                Save
                              </Button>
                            </Grid>
           </Grid>
            </Typography>
          </Box>
        </Fade>
      </Modal>

      </TableContainer>
    );
  }
  export default WorkExpTable;