import React from 'react';
import MainForm from './components/MainForm';  
import MegaProjectForm from './components/Mega Project/MegaProjectForm'; 
import JobNames from './components/JobNames'; 
import './App.css';
// import NotFound from './components/Error/NotFound'

import { useLocation, Routes ,Route} from 'react-router-dom';
import JobNames_HRequest from './components/Mega Project/JobNames_HRequest';
function App()  {
  return (
          <>
          <Routes onUpdate={() => window.scrollTo(0, 0)}>
                {/* <Route path="/" element={<JobNames/>}/>
                <Route path="/jobid" element={ <MainForm/> }/>  
                <Route path="/jobid/:id" element={ <MainForm/> }/>  */}
                <Route path="/" element={<JobNames_HRequest tag={"General"}/>}/>
                <Route path="/applynow" element={<MegaProjectForm tag={"applynow"}/>}/>
                <Route path="/jobcode/:id" element={<MegaProjectForm tag={"General"}/>}/>
                <Route path="/mega-project" element={ <JobNames_HRequest tag={"mega-project"}/> }/>
                <Route path="/mega-project/jobcode/:id" element={ <MegaProjectForm tag={"mega-project"}/> }/> 
                {/* <Route path='*' element={<NotFound/>}/> */}
          </Routes>
          <footer>
          <div>© <a href="https://rowad-rme.com">Rowad Modern Engineering</a></div>
          <div>All rights reserved. {new Date().getFullYear() }</div>
          </footer>
          </>
 
  );
};
export default App;