import * as yup from "yup";
const validationsForm =  yup.object().shape({

  new_name: yup.string().required('Company is required'),
  new_field: yup.string().required('Field is required'),
  new_positiontitle: yup.string().required('Job Title is required'),
  new_projecttype: yup.string().required('Project Type is required'),
  new_salary: yup.string().required('Salary is required'),
  new_employedfrom: yup.string().required('From is required'),
  new_employedtotxt: yup.string().required('To is required'),
  new_responsibilitiesachievements: yup.string().required('Resposibility & Acheivement is required'),
  new_reasonforleaving: yup.string().required('Reason for Leaving is required'),

});

export default validationsForm;
