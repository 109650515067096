import * as yup from "yup";
const validationsForm =  yup.object().shape({
  new_universitytxt: yup.string().required('University is required'),
  new_name: yup.string().required('Faculty is required'),
  new_from: yup.string().required('From is required'),
  new_to: yup.string().required('To is required'),
  new_degree: yup.string().required('Degree is required'),
  new_graderating: yup.string().required('Grade/Rating is required'),
  new_major: yup.string().required('Major is required'),
  new_minor: yup.string().required('Minor is required'),
});

export default validationsForm;
