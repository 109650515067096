import React from 'react';
import validationsForm from "./ValidationSchema";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {TableHead ,TableRow ,TableCell ,TableContainer,Table , TableBody,Box, Modal, Fade,Backdrop,
  Typography, TextField, Button, MenuItem, Grid,IconButton   } from '@mui/material';
import { Edit, Delete, ViewDaySharp } from '@material-ui/icons';
function UniversityTable({ data, handleEdit, handleDelete }) {
    const [open, setOpen] = React.useState(false);
    // const [editData, setEditData] = React.useState({});
    const formik= useFormik({
      initialValues:{
        id: 0,
        new_name:'',                                      // Training Program / Course Name
        new_centerinstitutecompany:'',                    // Center/ Institute / Company
        new_periodhours:'',                               // Period (Hours)
        new_fromdate:'',                                  // From
        new_todate:'',                                    // To
        new_descriptionobjectivecontentacquiredskills:'', // Description
      },
      validationSchema: validationsForm,
      
      onSubmit: values => { 
        const{new_fromdate,new_todate,...rest}=values
        handleEdit({
          "new_fromdate": new_fromdate.$y+'-'+Number(new_fromdate.$M+1)+'-'+new_fromdate.$D,
          "new_todate":  new_todate.$y+'-'+Number(new_todate.$M+1)+'-'+new_todate.$D,
          ...rest
        },data,"Extra Training");
        handleClose();
     },
    });

 
    const handleOpen = (rowData) => {
      setOpen(true);
      const {new_fromdate,new_todate,...rest}=rowData
      formik.setValues({"new_fromdate":dayjs(new_fromdate),"new_todate":dayjs(new_todate),...rest});
    };
  
    const handleClose = () => {
      setOpen(false);
      formik.setTouched({});
    };
 
  
    return (
      <TableContainer>
        <Table>
          {data.length>0?( //Appear Table Header if theres at 1 data
          <TableHead>
            <TableRow>
              <TableCell>Training Program / Course Name</TableCell>
              <TableCell>Center/ Institute / Company</TableCell>
              <TableCell>Period (Hours)</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          ):<></>}
          <TableBody>
            
            {data.map((row) => (
              <TableRow key={row.id}>
    
                <TableCell>{row.new_name}</TableCell>
                <TableCell>{row.new_centerinstitutecompany}</TableCell>
                <TableCell>{row. new_periodhours}</TableCell>
                <TableCell>{row.new_fromdate}</TableCell>
                <TableCell>{row.new_todate}</TableCell>          
                <TableCell>{row.new_descriptionobjectivecontentacquiredskills}</TableCell>
 
                <TableCell>
                  <IconButton onClick={() => handleOpen(row)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.id,"Extra Training")}>
                  <Delete />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
        <Fade in={open}>
          <Box className='popupaddedit'>
          <Typography id="transition-modal-title" variant="h4" component="h2">
            Edit Extra Training 
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_name"
                                label="Training Program / Course Name"
                                value={formik.values.new_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}                
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formik.touched.new_name && formik.errors.new_name}
                                error={ formik.touched.new_name  && Boolean(formik.errors.new_name)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_centerinstitutecompany"
                                label="Center/ Institute / Company"
                                value={formik.values.new_centerinstitutecompany}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formik.touched.new_centerinstitutecompany && formik.errors.new_centerinstitutecompany}
                                error={ formik.touched.new_centerinstitutecompany  && Boolean(formik.errors.new_centerinstitutecompany)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_periodhours"
                                label="Period (Hours)"
                                value={formik.values.new_periodhours}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formik.touched.new_periodhours && formik.errors.new_periodhours}
                                error={ formik.touched.new_periodhours  && Boolean(formik.errors.new_periodhours)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="From"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                value={ formik.values.new_fromdate || null}
                                onChange={(date) => formik.setFieldValue('new_fromdate',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formik.touched.new_fromdate  && Boolean(formik.errors.new_fromdate),
                                    helperText:formik.touched.new_fromdate && formik.errors.new_fromdate
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="To"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                // format='YYYY-MM-DD'
                                value={ formik.values.new_todate || null}
                                onChange={(date) => formik.setFieldValue('new_todate',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formik.touched.new_todate  && Boolean(formik.errors.new_todate),
                                    helperText:formik.touched.new_todate && formik.errors.new_todate
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
 
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_descriptionobjectivecontentacquiredskills"
                                label="Description"
                                value={formik.values.new_descriptionobjectivecontentacquiredskills}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                inputProps={{
                                  maxlength: 2000
                                }}
                                helperText={formik.touched.new_descriptionobjectivecontentacquiredskills && formik.errors.new_descriptionobjectivecontentacquiredskills}
                                error={ formik.touched.new_descriptionobjectivecontentacquiredskills  && Boolean(formik.errors.new_descriptionobjectivecontentacquiredskills)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <Button variant="contained"  color="success" type="button" onClick={formik.handleSubmit}>
                                Save
                              </Button>
                            </Grid>
                          </Grid>
            </Typography>
          </Box>
        </Fade>
      </Modal>

      </TableContainer>
    );
  }
  export default UniversityTable;