import React from 'react';
import validationsForm from "./ValidationSchema";
import { useFormik } from 'formik';
import {TableHead ,TableRow ,TableCell ,TableContainer,Table , TableBody,Box, Modal, Fade,Backdrop,
  Typography, TextField, Button, MenuItem, Grid,IconButton   } from '@mui/material';
import { Edit, Delete } from '@material-ui/icons';
function UniversityTable({ data, handleEdit, handleDelete }) {
    const [open, setOpen] = React.useState(false);
    const formik= useFormik({
      initialValues:{
        id: 0,
        new_languaget:{name:'',value:''}, 
        new_understandt:{name:'',value:''},
        new_speakt:{name:'',value:''},
        new_readwritet:{name:'',value:''},
      },
      validationSchema: validationsForm,
      
      onSubmit: values => { 
        handleEdit(values,data,"Language");
        handleClose();
     },
    });

 
    const handleOpen = (rowData) => {
      setOpen(true)
      formik.setValues(rowData);
    };
  
    const handleClose = () => {
      setOpen(false);
      formik.setTouched({});
    };
 
  
    return (
      <TableContainer>
        <Table>
          {data.length>0?( //Appear Table Header if theres at 1 data
          <TableHead>
            <TableRow>
              <TableCell>Language</TableCell>
              <TableCell>Understanding</TableCell>
              <TableCell>Speaking</TableCell>
              <TableCell>Reading/Writing</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          ):<></>}
          <TableBody>
       
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.new_languaget.name}</TableCell>
                <TableCell>{row.new_understandt.name}</TableCell>
                <TableCell>{row.new_speakt.name}</TableCell>
                <TableCell>{row.new_readwritet.name}</TableCell>
 
                <TableCell>
                  <IconButton onClick={() => handleOpen(row)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.id,"Language")}>
                  <Delete />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
        <Fade in={open}>
          <Box className='popupaddedit'>
          <Typography id="transition-modal-title" variant="h4" component="h2">
            Edit University 
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
                                <TextField required
                                  name="new_languaget"
                                  select
                                  label="Language"
                                  value={formik.values.new_languaget.value}
                                  onChange={(e, value) =>{
 
                                    if(value!=null){
                                      formik.setFieldValue("new_languaget", {value:value.props.value,name:value.props.children})
                                    } else {
                                      formik.setFieldValue("new_languaget", {value:'',name:''})
                                    }
                                  }}
                                   
                                  fullWidth
                                  margin="normal"
                                  onBlur={formik.handleBlur}
                                  helperText={formik.touched.new_languaget?.name && formik.errors.new_languaget?.name}
                                  error={ formik.touched.new_languaget?.name  && Boolean(formik.errors.new_languaget?.name)}
                                  id="new_languaget"
                                >{
                                  [
                                    {value:100000000,name:"ENGLISH"},
                                    {value:100000001,name:"ARABIC"},
                                    {value:100000003,name:"CHINESE"},
                                    {value:100000009,name:"GERMAN"},
                                    {value:100000011,name:"FRENCH"},
                                    {value:100000004,name:"HINDI"},
                                    {value:100000005,name:"PORTUGUESE"},
                                    {value:100000007,name:"RUSSIAN"},
                                    {value:100000006,name:"BENGALI"},
                                    {value:100000008,name:"JAPANESE"},
                                    {value:100000010,name:"KOREAN"},
                                    {value:100000012,name:"TURKISH"},
                                    {value:100000013,name:"VIETNAMESE"}
                                  ].map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>   
                            </Grid>
                              <Grid item xs={6} sm={3}>
                              <TextField required
                                    name="new_understandt"
                                    select
                                    label="Understanding"
                                    value={formik.values.new_understandt.value}
                                    onChange={(e, value) =>{
   
                                      if(value!=null){
                                        formik.setFieldValue("new_understandt", {value:value.props.value,name:value.props.children})
                                      } else {
                                        formik.setFieldValue("new_understandt", {value:'',name:''})
                                      }
                                    }}
                                    fullWidth
                                    margin="normal"
                                    onBlur={formik.handleBlur}
                                    helperText={formik.touched.new_understandt?.name && formik.errors.new_understandt?.name}
                                    error={ formik.touched.new_understandt?.name  && Boolean(formik.errors.new_understandt?.name)}
                                    
                                    id="new_degree"
                                  >{
                                    [
                                          {value:100000000,name:"Excellent"},
                                           {value:100000001,name:"Good"},
                                           {value:100000002,name:"Fair"}
                                    ].map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>   
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <TextField required
                                    name="new_speakt"
                                    select
                                    label="Speaking"
                                    value={formik.values.new_speakt.value}
                                    onChange={(e, value) =>{
                                      if(value!=null){
                                        formik.setFieldValue("new_speakt", {value:value.props.value,name:value.props.children})
                                      } else {
                                        formik.setFieldValue("new_speakt", {value:'',name:''})
                                      }
                                    }}
                                    fullWidth
                                    margin="normal"
                                    onBlur={formik.handleBlur}
                                    helperText={formik.touched.new_speakt?.name && formik.errors.new_speakt?.name}
                                    error={ formik.touched.new_speakt?.name  && Boolean(formik.errors.new_speakt?.name)} 
                                    id="new_speakt"
                                  >{
                                    [
                                      {value:100000000,name:"Excellent"},
                                      {value:100000001,name:"Good"},
                                      {value:100000002,name:"Fair"}
                                    ].map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                      </MenuItem>
                                      
                                    ))}
                                  </TextField>   
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <TextField required
                                    name="new_readwritet"
                                    select
                                    label="Reading/Writing"
                                    value={formik.values.new_readwritet.value}
                                    onChange={(e, value) =>{
   
                                      if(value!=null){
                                        formik.setFieldValue("new_readwritet", {value:value.props.value,name:value.props.children})
                                      } else {
                                        formik.setFieldValue("new_readwritet", {value:'',name:''})
                                      }
                                    }}
                                    fullWidth
                                    margin="normal"
                                    onBlur={formik.handleBlur}
                                    helperText={formik.touched.new_readwritet?.name && formik.errors.new_readwritet?.name}
                                    error={ formik.touched.new_readwritet?.name  && Boolean(formik.errors.new_readwritet?.name)}
                                    id="new_readwritet"
                                  >{
                                    [
                                           {value:100000000,name:"Excellent"},
                                           {value:100000001,name:"Good"},
                                           {value:100000002,name:"Fair"}
                                    ].map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>   
                            </Grid> 
          <Grid item xs={6} sm={4}>
            <Button variant="contained"  color="success" type="button" onClick={formik.handleSubmit}>
              Save
            </Button>
          </Grid>
                          </Grid>
            </Typography>
          </Box>
        </Fade>
      </Modal>

      </TableContainer>
    );
  }
  export default UniversityTable;