import * as yup from "yup";
import moment from 'moment';
import axios from "axios";
const validationsForm =  yup.object().shape({

  // new_iherebystatethatimmedicallyfit: yup.string().oneOf(["Yes"], 'You must accept the terms and conditions'),
 gtoken:yup.string().required('Captcha is Required'),
 attachment: yup.mixed().required('Attachment is required')
 .test(
  'fileType',
  'Only PDF files are allowed',
  (value) => value && value.type === 'application/pdf'
)
.test('fileSize', 'Attachment size exceeds 2MB', (value) => {
  if (!value) return true; // Skip validation if no file is selected
  return value.size <= 2 * 1024 * 1024; // 2MB in bytes
}),

  // new_name: yup.string().required("Required"),
  // new_email: yup
  //     .string()
  //     // .matches(EMAIL_REGX, "Invalid email address")
  //     .email("Email address is not valid")
  //     .required("Required"),

  // new_telephonenumber: 
  // yup.number()
  // .required("Required"),
  // new_birthdate:
  // yup.date()
  // // .transform(function (value, originalValue) {
  // //   if (this.isType(value)) {
  // //     return value;
  // //   }
  // //   const result = parse(originalValue, "YYYY-MM-DD", new Date());
  // //   return result;
  // // })
  // .required("Required")
  // .test(
  //   "DOB",
  //   "Minumum age 20",
  //   value => {
  //     return moment().diff(moment(value),'years') >= 20;
  //   }
  // ),
  //   new_placeofbirthcountriesguid:yup.object().shape({
  //     id: yup.string().required("Required"),
  //     name: yup.string().required(),
  //   }),
  //   new_homeaddress:yup.string().required("Required"),
  //   new_gender:yup.string().required("Required"),
  //   new_nationalityguid:yup.object().shape({
  //     id: yup.string().required("Required"),
  //     name: yup.string().required(),
  //   }),     
  //   new_homeaddressgovernrate:yup.object().shape({
  //     id: yup.string().required("Required"),
  //     name: yup.string().required(),
  //   }),                                                      // Nationality
  //   new_onationalityguid:yup.object().shape({
  //     id: yup.string().required("Required"),
  //     name: yup.string().required(),
  //   }),                                                          // Other Nationality

  //   new_religion:yup.string().required("Required"),
  //   new_nationalid: yup.string().required("Required")
  //   .test('Unique National id','National id already in use', 
  //   function(value){
  //     if(value.length===14){
  //       return new Promise((resolve, reject) => {
  //         axios.get(`${process.env.REACT_APP_Back_URL}/careers/nationalid/${value}`)
  //         .then(
  //           res => {
  //             if(res.data === 'National id already been taken')
  //             {
  //               resolve(false)
  //             }
  //             resolve(true)
  //             })
  //     })}
  //     else{
  //       return true
  //     }
  //   // else{
  //   //   handleClickReset = () => resetForm()
  //   // }

  //   }


  //   )
  //   .min(14, 'Must be exactly 14 digits')
  //   .max(14, 'Must be exactly 14 digits'),
  //   new_expirydate:yup.string().required("Required")
  //   // .test(
  //   //   "DOB",
  //   //   "error message",
  //   //   value => {
  //   //     return moment().diff(moment(value),'years') > 0;
  //   //   })
  //   ,            // National ID Expirary ID
  //   new_dependents:yup.number().required("Required"),

  //   new_maritalstatus: yup.number().required("Required"),

        // new_maritalstatus:yup.string().required("Required"),
});

export default validationsForm;
