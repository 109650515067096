import * as yup from "yup";
import moment from 'moment';
import axios from "axios";
const validationsForm =  yup.object().shape({
  new_name: yup.string().required("Required"),
  new_email: yup
      .string()
      // .matches(EMAIL_REGX, "Invalid email address")
      .email("Email address is not valid")
      .required("Required"),

  new_telephonenumber: 
  yup.number()
  .required("Required"),
  new_birthdate:
  yup.date()
  // .transform(function (value, originalValue) {
  //   if (this.isType(value)) {
  //     return value;
  //   }
  //   const result = parse(originalValue, "YYYY-MM-DD", new Date());
  //   return result;
  // })
  .required("Required")
  .test(
    "DOB",
    "Minumum age 21",
    value => {
      return moment().diff(moment(value),'years') >= 21;
    }
  ),
    new_placeofbirthcountriesguid:yup.object().shape({
      id: yup.string().required("Required"),
      name: yup.string().required(),
    }),
    new_homeaddress:yup.string().required("Required"),
    new_gender:yup.string().required("Required"),
    new_nationalityguid:yup.object().shape({
      id: yup.string().required("Required"),
      name: yup.string().required(),
    }),     
    new_homeaddressgovernrate:yup.object().shape({
      id: yup.string().required("Required"),
      name: yup.string().required(),
    }),                                                      // Nationality
    new_nationalid: yup.string().required("Required")
    .min(14, 'Must be exactly 14 digits')
    .max(14, 'Must be exactly 14 digits'),
    new_dependents:yup.number().required("Required"),

    new_maritalstatus: yup.number().required("Required"),

        // new_maritalstatus:yup.string().required("Required"),
});

export default validationsForm;
