import * as yup from "yup";
const validationsForm =  yup.object().shape({
    // Corporate Record
    // Have you ever been Applied for rowad before
    new_haveyoueverbeenappliedforrowadbefore: yup.boolean().required("Required"),
    // If Yes Please give details When Where How Result
    new_ifyespleasegivedetails: yup.string() 
    .when("new_haveyoueverbeenappliedforrowadbefore", {
    is: (value) => value === true,
    then: (schema) => schema.required('Required'),
    otherwise: (schema) => schema.optional(),
    }), 
    // Have you ever been employd by rowad before                 
    new_haveyoueverbeenemployedbyrowad:yup.boolean().required("Required"),  
    // Position
    new_ifyespleasegivedetailswhowherewhen:yup.string()        
    .when("new_haveyoueverbeenemployedbyrowad", {
      is: (value) =>  value === true,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.optional(),
      }),
    // Site
    new_crsite:yup.string()                                    
    .when("new_haveyoueverbeenemployedbyrowad", {
      is: (value) =>  value === true,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.optional(),
      }),
    // Do you have any relatives Hired by any of ROWAD Co?
    new_doyouhaveanyrelativeshiredbyanyofrowadco:yup.boolean().required("Required"),
      // If Yes Please give details Who, Where, When
      new_ifyespleasegivedetailswhowherewhenrelativ:yup.string()  
      .when("new_doyouhaveanyrelativeshiredbyanyofrowadco", {
        is: (value) => value && value === true,
        then: (schema) => schema.required('Required'),
        otherwise: (schema) => schema.optional(),
        }), 
    // Medical Check
    // Did you operate any surgeries ?
    new_didyouoperateanysurgeries:yup.string().required("Required"),
    // Do you have any chronic disease?                
    new_doyouhaveanychronicdisease:yup.string().required("Required"),
    // if yes please state           
    new_ifyespleasestate:yup.string()  
    .when("new_doyouhaveanychronicdisease", {
      is: (value) => value && value === "Yes",
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.optional(),
      }), 
    // I hereby state that I’m medically fit and free from infectious diseases and all that may hinder the implementation of the contractual tasks.                         
     new_iherebystatethatimmedicallyfit:yup.string().oneOf(["Yes"], 'Checkbox must be checked'),
    // I confirm I am aware that I may be subject, upon the employer’s request, to medical Examinations required b       
     new_iconfirmiamawarethatimaybesubject:yup.string().oneOf(["Yes"], 'Checkbox must be checked'),
    // I undertake to submit all the required employment documents including attested educational and experience certificates as pe       
     new_iundertaketosubmitalltherequiredemploymen:yup.string().oneOf(["Yes"], 'Checkbox must be checked'), 

    // Reference Check
    // First Ref.
    // Full Name
    new_fullname:yup.string().required("Required"),
    // Company    
    new_company:yup.string().required("Required"),
    // Relation
    new_relation:yup.string().required("Required"),
    // Position 
    new_position:yup.string().required("Required"),
    // Contact Phone
    new_contactphone:yup.number().required("Required"), 
    // Second Ref.
    // Full Name
    new_new_rf2fullname:yup.string().required("Required"),
     // Company  
    new_rf2company:yup.string().required("Required"),
    // Relation
    new_rf2relation:yup.string().required("Required"), 
    // Position    
    new_rf2position:yup.string().required("Required"),
    // Contact Phone  
    new_rf2contactphone:yup.number().required("Required"), 
});

export default validationsForm;
